<template>
  <div class="background">
    <div class="logo-container">
      <img class="logo" src="/assets/logo.svg" />
    </div>

    <div class="main">
      <div class="custom"></div>
      <h2 class="uppertitle">The easiest way of</h2>
      <ul>
        <li>Customized Decals & Stickers</li>
        <li>Signage</li>
        <li>Partial & Full Wrap</li>
        <li>Vinyl sale</li>
      </ul>
      <h2 class="lowertitle">We will be back soon...</h2>
    </div>

    <div class="abstract1"></div>
    <div class="abstract2"></div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "HomeView",
  components: {},
};
</script>

<style>
.background {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  background-image: url("../../public/assets/bg.png");
  background-size: cover;
  background-position: center;

  overflow: hidden;
}

.logo-container {
  position: absolute;
  top: 4vw;
  left: 4vw;
}

.logo {
  height: 3vh;
}

.abstract1 {
  position: absolute;
  bottom: -25vh;
  left: -40vw;

  width: 100vw;
  height: 100vh;

  background-image: url("../../public/assets/abstract1.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

  z-index: 50;
}

.abstract2 {
  position: absolute;
  top: -60vh;
  right: -65vw;

  width: 150vw;
  height: 150vh;

  background-image: url("../../public/assets/abstract2.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

  z-index: 50;
}

.custom {
  position: absolute;
  top: 50vh;
  left: 50vw;

  transform: translate(-50%, -50%);

  width: 50vw;
  height: 100vh;

  background-image: url("../../public/assets/customization.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.main {
  color: white;
  height: 100vh;
  width: 50vw;
  margin-left: 25vw;

  display: flex;
  justify-content: center;

  flex-direction: column;
  z-index: 100;
}

.main h2 {
  font-weight: 100;
  font-size: 32pt;

  font-family: Poppins, sans-serif;
}

.main .uppertitle {
  position: absolute;
  top: 30vh;
}

.main ul {
  position: absolute;
  bottom: 35vh;
  left: 25vw;
  right: 25vw;
}

.main .lowertitle {
  position: absolute;
  bottom: 20vh;
  right: 25vw;
}

.main ul {
  margin: 0 5vw 0 5vw;
  padding: 0;

  list-style-type: none;

  display: flex;
  justify-content: space-between;
}

.main ul li {
  list-style-type: none;
  padding: 1em 2em 1em 2em;
  border: 1px solid white;
  border-radius: 2em;
}

@media only screen and (max-width: 600px) {
  .logo-container {
    top: 5vh;
    left: 10vw;
  }

  .main {
    color: white;
    height: 100vh;
    width: 80vw;
    margin-left: 10vw;

    display: flex;
    flex-direction: column;
  }

  .main .uppertitle {
    position: absolute;
    top: 10vh;
    width: 50vw;

    text-align: left;
    font-size: 22pt;

    font-weight: 200;
  }

  .main ul {
    position: initial;
    display: flex;
    flex-direction: column;

    z-index: 100;

    margin: 0;
    padding: 0;
  }

  .main ul li {
    margin: 1vh 0 1vh 0;
    background-color: rgba(255, 255, 255, 0.85);
    color: #415d78;
    opacity: 0.85;
  }

  .main .lowertitle {
    position: absolute;
    bottom: 5vh;
    right: 10vw;
    width: 50vw;

    text-align: right;
    font-size: 22pt;

    font-weight: 200;
  }

  .custom {
    position: absolute;
    z-index: 10;
    width: 100vw;
    transform: translate(-50%, -50%) rotateZ(-15deg);
  }
}
</style>
